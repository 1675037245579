import { ref } from "vue"
import { useRouter } from "vue-router"

import {
  hasBeenNotifiedAboutStaleness,
  isUserLoggedIn,
  setUserLoggedInStatus,
  showFlashMessage,
} from "@/utils/GlobalState"
import { gettext } from "@/utils/Translation"
import { isOnline } from "@/utils/Utilities"

const { $gettext } = gettext
const loggedOutMsg = $gettext("The information shown here might be out of date. Log in to update it.")

/*
 * This composable encapsulates the execution of a server query and the handling of
 * function exceptions and cases where the user is not online. It shows flash messages
 * as appropriate.
 *
 * This composable returns an object with two properties:
 * - refreshUnderway: a ref to a boolean that indicates whether the server is being
 *   polled at the moment
 * - serverRefresher: an async function with the following parameters:
 *     - an async function to call to perform the refresh operation
 *     - an array of args that function should be called with (can be empty)
 *     - a key for looking up or persisting the last time the user was notified about staleness
 *       in the current context
 *     - a boolean indicating if the refresh attempt was manually triggered
 *
 * serverRefresher should not return a value.
 *
 */
export default function useServerRefresher() {
  const router = useRouter()
  const refreshUnderway = ref(false)

  // What happens if the user is offline when a refresh is attempted?
  // If the user needs to be notified that content might be stale, throw an exception.
  // Otherwise silently return nothing. Reasons for notification:
  // * they explicitly requested a refresh, and thus are expecting some sort of feedback if it fails, OR
  // * it's been long enough since they last were notified that it bears repeating. (See
  //   hasBeenNotifiedAboutStaleness for what "long enough" is.)
  function handleOfflineSituation(staleNotificationQueryKey: string, wasManuallyTriggered: boolean) {
    if (wasManuallyTriggered || !hasBeenNotifiedAboutStaleness(staleNotificationQueryKey)) {
      const error = new Error()
      error.name = "CONNECTIVITY_REQUIRED"
      throw error
    }
  }

  async function serverRefresher(asyncServerFunction: () => Promise<void>, functionArgs: Array<unknown>, staleNotificationQueryKey: string, wasManuallyTriggered = false) {
    const loginUrl = router.resolve({ name: "Login" })
    const loggedOutContent = `${loggedOutMsg} <a href="${loginUrl.href}">${$gettext('Log in now')}</a>`
    if (!isUserLoggedIn().value) {
      showFlashMessage({ msg: loggedOutContent, class: "is-warning", appearanceDelay: 0 })
    }
    else if (!isOnline()) {
      handleOfflineSituation(staleNotificationQueryKey, wasManuallyTriggered)
    }
    else {
      try {
        refreshUnderway.value = true
        await asyncServerFunction(...functionArgs)
      }
      catch (error) {
        console.log(error)
        if (error.name === "CONNECTIVITY_REQUIRED") {
          handleOfflineSituation(staleNotificationQueryKey, wasManuallyTriggered)
        }
        else if (error.name === "LOGIN_REQUIRED") {
          setUserLoggedInStatus(false)
          showFlashMessage({ msg: loggedOutContent, class: "is-warning", appearanceDelay: 0 })
        }
        else {
          showFlashMessage({
            msg: "Something unexpected happened. Try again later.",
            class: "is-danger",
            appearanceDelay: 0,
          })
        }
      }
      finally {
        refreshUnderway.value = false
      }
    }
  }

  return { serverRefresher, refreshUnderway }
}
