import dayjs from "dayjs"

import { IBestPracticeAssessment, IFeedingScreening } from "@/db"
import { Child } from "@/models/Child"
import { isAssessmentEditable } from "@/models/Common"
import { gettext } from "@/utils/Translation.js"
import { isKnown, sum } from "@/utils/Utilities"

const { $gettext } = gettext

const containsAYes = (...args: Array<string>) => args.some(a => a === "yes")
const isAllUnknown = (...args: Array<string>) => args.every(a => a === "unknown" || !isKnown(a))

interface RecommendationItem {
  id: string
  // At least one of the next two must be supplied, or it's
  // not much of a recommendation item!
  interpretation?: string
  recommendation?: string
  learnMore?: string
  learnMoreLabel?: string
  referral?: string
}

export class FeedingScreening implements IFeedingScreening {
  id: number
  child: Child
  isComplete: boolean

  _ageInMonths: number
  _ageInYears: number

  childId: number
  siteId: number
  cmiId?: number
  dateOfAssessment: Date
  dateCreated?: Date
  dueDate?: Date
  notes?: object
  creatorName?: string

  doesChildCommunicateHunger: boolean
  doesChildEatEnough: boolean
  mealtimeDuration: string
  doesChildShowSignsOfAspiration: boolean
  doesChildEatReluctantly: boolean
  isChildNotGainingWeight: boolean
  areMealtimesStressful: boolean
  doesCaregiverHaveMealtimeConcerns: boolean

  hasReferrals: boolean

  constructor(child: Child, data: IFeedingScreening) {
    this.child = child instanceof Child ? child : new Child(child)
    this.isComplete = false
    for (const [key, value] of Object.entries(data)) {
      this[key] = value
    }
    if (this.cmiId) {
      // Presumably imported from server
      this.isComplete = true
    }
  }

  get ageInMonths() {
    if (!this._ageInMonths && this.dateOfAssessment && this.child.dob) {
      this._ageInMonths = dayjs(this.dateOfAssessment).diff(this.child.dob, "month", true)
    }
    return this._ageInMonths
  }

  get isAtRiskOfFeedingDifficulties() {
    const recs = this.getInterpretationsAndRecommendations()
    return recs.length >= 2
  }

  get reportCard(): { stoplightColor: string, statements: Array<string> } {
    if (!this.isComplete) {
      return { stoplightColor: "", statements: [] }
    }
    if (this.isAtRiskOfFeedingDifficulties) {
      return {
        stoplightColor: "red",
        statements: [$gettext("Child is at risk for feeding difficulties")],
      }
    }
    else {
      return {
        stoplightColor: "green",
        statements: [$gettext("Child does not seem to be at risk for feeding difficulties")],
      }
    }
  }

  getInterpretationsAndRecommendations(): Array<RecommendationItem> {
    const results = []

    if (this.doesChildCommunicateHunger === false) {
      const rec = {
        id: "doesChildCommunicateHunger",
        interpretation: $gettext("The child does not always show when they are hungry or full, or their signals are hard to understand."),
        recommendation: $gettext("Observe the child to learn when they show signs of hunger and fullness, and respond promptly to them."),
      } as RecommendationItem
      if (this.ageInMonths <= 6) {
        rec.learnMore = $gettext("Infants may show signs of hunger such as rooting, increased movement, bringing hands to mouth or crying. Fullness cues include turning away, falling asleep, sealing lips, or open fists.")
      }
      else {
        rec.learnMore = $gettext("Children may communicate hunger using words, gestures or facial expressions. Non-verbal cues may include reaching or getting excited when food is present. Fullness cues include closing mouth, becoming distracted, pushing or turning away.")
      }
      results.push(rec)
    }
    if (this.doesChildEatEnough === false) {
      results.push({
        id: "doesChildEatEnough",
        interpretation: $gettext("The child’s diet may not be adequate for healthy growth and development."),
        recommendation: $gettext("Make sure the child’s meals are high in energy (calories) and important nutrients like protein, vitamins and minerals. This is important for all children but especially children who eat small amounts. If possible, offer smaller but more frequent nutrient-dense meals."),
      })
    }
    if (this.mealtimeDuration === "fast") {
      results.push({
        id: "mealtimeDuration",
        interpretation: $gettext("The child’s mealtime is much shorter than other children the same age."),
        recommendation: $gettext("If the child eats very rapidly, offer bites of food more slowly. If the child feeds themself, offer smaller portions at one time to encourage a slower pace."),
      })
    }
    else if (this.mealtimeDuration === "slow") {
      results.push({
        id: "mealtimeDuration",
        interpretation: $gettext("The child’s mealtime is much longer than other children the same age."),
        recommendation: $gettext("If the child eats very slowly, make sure they are alert, calm, and ready to eat. This will encourage more efficient feeding. Building the child’s feeding skills and providing foods in the appropriate texture supports safe and efficient feeding."),
      })
    }
    if (this.doesChildShowSignsOfAspiration) {
      results.push({
        id: "doesChildShowSignsOfAspiration",
        interpretation: $gettext("Coughing and choking during or after feeding may indicate the child is having difficulty swallowing safely."),
        recommendation: $gettext("If a child often has trouble breathing or respiratory issues, they might be at risk for aspiration. Make sure the child is positioned well with enough support to encourage safe swallowing. Sit the child as upright as possible while eating and drinking and for at least 15 minutes afterwards. Make sure the texture of their food provided matches their child's feeding skills."),
        learnMore: $gettext("Aspiration is when food or liquid enters the airway or lungs instead of the tube leading to the stomach (esophagus). You cannot know for certain if someone is aspirating just by looking at them. However, there are sometimes signs that may suggest the child may be aspirating. If aspiration occurs frequently, in large amounts, or the child is not able to cough sufficiently, it can lead to respiratory problems, pneumonia, dehydration, malnutrition, weight loss, and increased risk of illness"),
        referral: $gettext("Refer to doctor for evaluation of respiratory concerns"),
      })
    }
    if (this.doesChildEatReluctantly) {
      results.push({
        id: "doesChildEatReluctantly",
        interpretation: $gettext("It takes extra effort to get the child to eat compared to other children the same age."),
        recommendation: $gettext("Always be responsive to the child’s communication. Make sure your strategies are helping and the child is comfortable with them. When possible, feed the child at early signs of hunger. If they show signs of fullness before the meal is finished, stop feeding. Encourage the child, but never force them to eat or drink."),
      })
    }
    if (this.isChildNotGainingWeight) {
      results.push({
        id: "isChildNotGainingWeight",
        interpretation: $gettext("The child may have lost weight or is not gaining weight as expected."),
        recommendation: $gettext("Losing weight or not gaining enough weight could be due to not eating enough of the right foods or could be due to frequent illness. Complete a growth assessment for the child, or review their most recent growth assessment results."),
        learnMore: $gettext("A growth assessment involves taking weight and length/height measurements and comparing them to what is expected for the child’s age. Could poor weight gain be due to recent illness or not eating enough?"),
      })
    }
    if (this.areMealtimesStressful) {
      results.push({
        id: "areMealtimesStressful",
        interpretation: $gettext("Mealtimes are stressful for the child or the caregiver."),
        recommendation: $gettext("Mealtimes are important for healthy growth and development, but not only because of the food. Mealtime is also an opportunity for care and connection. Responsive feeding and nurturing care during mealtime are important for the child and the caregiver."),
      })
    }
    if (this.doesCaregiverHaveMealtimeConcerns) {
      results.push({
        id: "doesCaregiverHaveMealtimeConcerns",
        interpretation: $gettext("The child’s caregiver has concerns about their feeding."),
        recommendation: "Difficulties with feeding can be stressful for the child and the caregiver. Their concerns are important and valid. Ask questions to understand more about the caregiver’s concerns.",
      })
    }
    return results
  }

  get referrals() {
    return this.getInterpretationsAndRecommendations()
      .filter(item => item.referral)
      .map(item => { return { id: item.id, content: item.referral } })
  }

  // Return the date by which we would like a referral to be made. We're saying
  // two weeks from the date of assessment. Note: this doesn't check to see *if*
  // a referral was made.
  get referralDate() {
    return dayjs(this.dateOfAssessment).add(2, "week").toDate()
  }
}


export class ChildBestPracticeAssessment implements IBestPracticeAssessment {
  id: number
  child: Child
  isComplete: boolean

  _ageInMonths: number
  _ageInDays: number
  _responsiveFeedingCaregiverVerdict: boolean

  cmiId?: number
  childId: number
  siteId: number
  dateOfAssessment: Date
  dueDate?: Date
  notes?: object
  creatorName?: string
  tools: Array<string>
  textures: Array<string>
  feeder: string
  bf?: string
  rf1?: boolean
  rf2?: boolean
  rf3?: boolean
  rf4?: boolean
  rf5?: boolean
  rf6?: boolean
  rf7?: boolean
  rf8?: string
  fp1?: boolean
  fp2?: string
  fp3?: boolean
  fp4?: boolean
  fp5?: boolean
  mf?: number
  mff?: number
  fg1a?: string
  fg2a?: string
  fg1b?: string
  fg2b?: string
  fg2c?: string
  fg3a?: string
  fg3b?: string
  fg4?: string
  fg5?: string
  fg6?: string
  fg7?: string
  fg8?: string
  hasReferrals: boolean

  constructor(child: Child, data: IBestPracticeAssessment) {
    this.child = child instanceof Child ? child : new Child(child)
    this.isComplete = false
    for (const [key, value] of Object.entries(data)) {
      this[key] = value
    }
    if (this.cmiId) {
      // Presumably imported from server
      this.isComplete = true
    }
  }

  get ageInDays() {
    if (!this._ageInDays && this.dateOfAssessment && this.child.dob) {
      this._ageInDays = dayjs(this.dateOfAssessment).diff(this.child.dob, "day", true)
    }
    return this._ageInDays
  }

  get ageInMonths() {
    if (!this._ageInMonths && this.dateOfAssessment && this.child.dob) {
      this._ageInMonths = dayjs(this.dateOfAssessment).diff(this.child.dob, "month", true)
    }
    return this._ageInMonths
  }

  get exclusivelyBreastfeeds() {
    return this.bf === "no"
  }

  // alias
  get ebf() {
    return this.exclusivelyBreastfeeds
  }

  get minimimumMealFrequency() {
    return this.getMinimumMealFrequencyRecommendation(true)
  }

  // alias
  get mmf() {
    return this.minimimumMealFrequency
  }

  get breastfeedingSummaryStatement(): string | null {
    if (!this.bf) return
    if (this.exclusivelyBreastfeeds) {
      return $gettext("The infant exclusively breastfeeds.")
    }
    else if (this.bf === "formula") {
      return $gettext("The infant takes breast milk and formula.")
    }
    else {
      return $gettext("The infant does not exclusively breastfeed.")
    }
  }

  get breastfeedingStoplightColor(): string | null {
    if (!this.bf) return
    if (this.bf === "formula") return "yellow"
    return this.exclusivelyBreastfeeds ? "green" : "red"
  }

  getBreastfeedingRecommendation(): RecommendationItem | null {
    if (!this.bf) return
    switch (this.bf) {
      case "no":
        return {
          id: "ebf",
          interpretation: $gettext("The infant exclusively breastfeeds."),
          recommendation: $gettext("Continue providing breast milk exclusively for the first 6 months. It provides all the nutrients the infant needs to grow well and protects them from many illnesses. There is no need to give anything else, not even water, during the infant’s first 6 months. Medicines are appropriate if they are prescribed by a health provider."),
        }
      case "water":
        return {
          id: "ebf",
          interpretation: $gettext("The infant receives water."),
          recommendation: $gettext("Only breast milk is recommended for the first 6 months because it provides all the nutrients infants need to grow well and protect from many illnesses. There is no need to give anything else, not even water, during the infant’s first 6 months."),
          learnMore: $gettext("Breast milk contains a lot of water, so even during very hot weather, breast milk alone is enough to satisfy the infant’s thirst. Giving them anything other than breast milk will cause them to suckle less and will reduce the amount of breast milk that the body produces. Water can also make the infant sick."),
        }
      case "formula":
        return {
          id: "ebf",
          interpretation: $gettext("The infant receives infant formula."),
          recommendation: $gettext("Breast milk is the best and safest form of nutrition for children at this age. For infants without access to breast milk, commercial infant formula, safely and properly prepared, is the best alternative."),
          // learnMore: $gettext(""), XXX: Need to link to “Commercial Infant Formula” in resource library. Add: "Learn about infant formula preparation and alternatives."
        }
      case "food":
        return {
          id: "ebf",
          interpretation: $gettext("The infant receives food or other beverages."),
          recommendation: $gettext("Breast milk is recommended exclusively for the first 6 months because it provides the best and safest nutrition the infant needs to grow well."),
          learnMore: $gettext("Breast milk provides all the food and water that infants need and it protects them from many illnesses. There is no need to give anything else during the baby’s first 6 months. Other liquids and foods can also make them sick."),
          // XXX: add to above: If the infant does not have access to breast milk, learn more about infant formula and safe alternatives (link to resource library).
        }
    }
  }

  get childResponsiveScore(): number {
    let score = 0
    if (this.rf1) score++
    if (this.rf2) score++
    if (this.rf3) score++
    return score
  }

  get caregiverResponsiveScore(): number | null {
    if (!isKnown(this.rf5)) {
      return
    }
    let score = 0
    if (this.rf4) score++
    if (this.rf5) score++
    if (this.rf6) score++
    if (this.rf7) score++
    if (this.rf8 === "yes") score++
    else if (this.rf8 === "forces") score -= 2
    return score
  }

  getResponsiveFeedingRecommendations(): Array<RecommendationItem> | boolean {
    const results = []
    // First, the child side of things. We only make a recommendation
    // if they don't check any of the boxes for communication. (Not all
    // children will have answered all of the questions, though.)
    if (!this.childResponsiveScore) {
      if (this.ageInMonths <= 6) {
        results.push({
          id: "rcf",
          interpretation: $gettext("The infant may need support to improve communication for feeding."),
          recommendation: $gettext("Make eye contact, talk to or interact with the infant during feeding. Pay attention to the infant’s cues to learn how they communicate hunger so you can respond."),
          learnMoreLabel: $gettext("Why?"),
          learnMore: $gettext("Infants give cues to communicate their needs even without talking. Respond promptly to the infant’s cues to build trust and support communication skills."),
        })
      }
      else {
        results.push({
          id: "rcf",
          interpretation: $gettext("The child may need support to improve communication for feeding."),
          recommendation: $gettext("Pay attention to the child’s cues and learn how the child communicates hunger so you can respond promptly. Make eye contact, talk to or interact with the child during feeding to encourage communication."),
        })
      }
    }
    else {
      if (this.ageInMonths <= 6) {
        results.push({
          id: "rcf",
          interpretation: $gettext("The infant is communicating for feeding."),
        })
      }
      else {
        results.push({
          id: "rcf",
          interpretation: $gettext("The child is communicating for feeding."),
        })
      }
    }

    // Some kids don't even get these questions; short-circuit if so.
    if (!isKnown(this.responsiveFeedingCaregiverVerdict)) {
      return results
    }

    const responsiveFeedingDefinition = $gettext(
      "Responsive feeding is when a child communicates hunger, fullness, discomfort, or readiness to eat and the adult recognizes it and responds warmly and promptly. Responsive feeding practices encourage independence and self-regulation and support cognitive, emotional, and social development."
    )
    if (this.responsiveFeedingCaregiverVerdict) {
      results.push({
        id: "raf",
        interpretation: $gettext("Feeding practices are responsive."),
        recommendation: $gettext("Continue responsive feeding practices. Responsive feeding builds trust, strengthens the child’s skills, and supports development."),
        learnMore: responsiveFeedingDefinition,
      })
    }
    else if (this.ageInMonths > 6) {
      results.push({
        id: "raf",
        interpretation: $gettext("Feeding practices are not responsive."),
        recommendation: $gettext("Feed the child with care, patience, and good humor. Responsive feeding practices make mealtimes positive, enjoyable, and a safe time for learning new skills."),
        learnMore: responsiveFeedingDefinition,
      })
    }
    else {
      results.push({
        id: "raf",
        interpretation: $gettext("Feeding practices are not responsive."),
        recommendation: $gettext("Feed the infant with care and patience. Responsive feeding practices make feeding time positive, enjoyable and a safe time for learning skills."),
        learnMore: $gettext("Responsive feeding is when an infant communicates hunger, fullness, discomfort, or readiness to eat and the adult recognizes it and responds warmly and promptly. Responsive feeding practices encourage self-regulation and support cognitive, emotional, and social development."),
      })
    }
    return results
  }

  getResponsiveFeedingSpecifics(): Array<RecommendationItem> {
    const results = []
    // We don't return specifics if their score is ok (or unknown).
    if (!isKnown(this.caregiverResponsiveScore) || this.responsiveFeedingCaregiverVerdict) {
      return results
    }
    if (this.rf4 === false) {
      results.push({
        id: "rf4",
        recommendation: $gettext("While feeding, sit in front of the child at eye level. Do not stand above, behind, or to the side of the child."),
        learnMoreLabel: $gettext("Why?"),
        learnMore: $gettext("This encourages communication, safe positioning for the child, and allows you to be more comfortable during mealtime."),
      })
    }
    if (this.rf5 === false) {
      results.push({
        id: "rf5",
        recommendation: $gettext("Talk to, sing to, or interact with the child during feeding."),
        learnMoreLabel: $gettext("Why?"),
        learnMore: $gettext("This promotes communication skills and positive mealtimes."),
      })
    }
    if (this.rf6 === false) {
      if (this.ageInMonths > 6) {
        results.push({
          id: "rf6",
          recommendation: $gettext("Pay attention to the child’s cues and respond promptly and warmly to the child’s communication."),
        })
      }
      else {
        results.push({
          id: "rf6",
          recommendation: $gettext("Pay attention to the infant’s cues and respond promptly and warmly."),
        })
      }
    }
    if (this.rf7 === false) {
      results.push({
        id: "rf7",
        recommendation: $gettext("Wait for the child to show they are ready for another bite."),
        learnMoreLabel: $gettext("Why?"),
        learnMore: $gettext("This allows the child to set the pace for safe and efficient feeding. If more food is placed in the mouth before they have finished, it can become difficult to chew and swallow the food. This is not safe and may increase the child's risk for choking or aspiration."),
      })
    }
    if (isKnown(this.rf8) && this.rf8 !== "yes") {
      results.push({
        id: "rf8",
        recommendation: $gettext("Feed the child with positive verbal encouragement, but avoid coercion or forcing."),
        learnMoreLabel: $gettext("Why?"),
        learnMore: $gettext("While forcing may be common in some cultures or in response to concerns for feeding or growth, it is never safe for children. It can lead to fear, choking, respiratory complications, and other harm. Forcing a child to eat can also disrupt feeding cues."),
      })
    }
    return results
  }

  // Return true for positive verdict
  get responsiveFeedingCaregiverVerdict() {
    if (this._responsiveFeedingCaregiverVerdict === undefined) {
      if (!isKnown(this.rf5)) return
      const threshold = this.ageInMonths > 6 && this.textures.includes("solids") ? 4 : 2
      const caregiverScore = this.caregiverResponsiveScore
      this._responsiveFeedingCaregiverVerdict = caregiverScore >= threshold
    }
    return this._responsiveFeedingCaregiverVerdict
  }

  get responsiveFeedingVerdict(): boolean | null {
    if (isKnown(this.responsiveFeedingCaregiverVerdict) && !this.responsiveFeedingCaregiverVerdict) {
      return false
    }
    return this.childResponsiveScore ? true : false
  }

  get responsiveFeedingSummaryStatement(): string {
    const recs = this.getResponsiveFeedingRecommendations() as Array<RecommendationItem>
    return recs
      .map(rec => rec.interpretation)
      .join(" ")
  }

  get responsiveFeedingStoplightColor() {
    return this.responsiveFeedingVerdict ? "green" : "red"
  }

  getOtherFeedingPracticesRecommendations(): Array<RecommendationItem> {
    const results = []

    if (this.fp1 === false) {
      results.push({
        id: "fp1",
        interpretation: $gettext("The child does not sit with others during mealtimes."),
        recommendation: $gettext("Whenever possible, encourage mealtimes while sitting together and facing others."),
        learnMoreLabel: $gettext("Why?"),
        learnMore: $gettext("This helps children develop social and communication skills."),
      })
    }
    if (this.fp2 === "no have tried") {
      results.push({
        id: "fp2",
        interpretation: $gettext("The child needs support to build skills for self-feeding."),
        recommendation: $gettext("Practice will improve skills. Let the child self-feed according to their abilities and interests. Offer opportunities for the child to hold spoons or cups and to reach for food. After properly cleaning hands, help the child to explore food and bring hands toward the mouth. The child may need physical support to move hands or hold food/utensils."),
      })
    }
    else if (this.fp2 === "no have not tried") {
      results.push({
        id: "fp2",
        interpretation: $gettext("The child needs opportunities to build skills for self-feeding."),
        recommendation: $gettext("Let the child feed themself according to their abilities and interests. Offer opportunities to practice skills. After properly cleaning hands, help the child to explore food with hands, touch spoons or cups, and bring hands toward the mouth."),
      })
    }
    if (this.fp3 === false) {
      results.push({
        id: "fp3",
        interpretation: $gettext("The infant is not always held supportively during feeding."),
        recommendation: $gettext("Always hold the infant with their head and body supported while feeding for safe and efficient feeding."),
        // learnMoreLabel: $gettext("Why?"),
        // learnMore: $gettext("This helps children develop social and communication skills."),
      })
    }
    if (this.fp4) {
      results.push({
        id: "fp4",
        interpretation: $gettext("Bottle nipples have cuts or tears."),
        recommendation: $gettext("When bottle feeding, use nipples that have not been cut, split, or stretched."),
        learnMoreLabel: $gettext("Why?"),
        learnMore: $gettext("Using an intact nipple ensures better hygiene and allows the child to better control how quickly they eat."),
      })
    }
    if (this.fp5 === false) {
      results.push({
        id: "fp5",
        interpretation: $gettext("The child’s spoon is too large."),
        recommendation: $gettext("Use a small spoon that fits easily in the child’s mouth."),
        learnMoreLabel: $gettext("Why?"),
        learnMore: $gettext("This helps ensure bite sizes are appropriate and allows the child to better control and swallow food safely and efficiently."),
      })
    }
    return results
  }

  // Return true for positive verdict
  get otherFeedingPracticesVerdict() {
    return !this.getOtherFeedingPracticesRecommendations().length
  }

  get otherFeedingPracticesSummaryStatement() {
    if (this.otherFeedingPracticesVerdict) {
      return $gettext("Feeding practices are appropriate to support safety and development.")
    }
    else {
      return $gettext("Improve feeding practices to support safety and development.")
    }
  }

  get otherFeedingPracticesStoplightColor() {
    return this.otherFeedingPracticesVerdict ? "green" : "red"
  }

  get dietQualityStoplightColor(): string | null {
    if (this.ageInDays >= 183 && !this.textures.includes("solids")) {
      return "red"
    }
    if (!isKnown(this.mf)) return
    const freq = this.getMinimumMealFrequencyRecommendation(true)
    const divers = this.getMinimumDietDiversitySummary(true)
    // A lot of permutations. If at least one is known, we can return a color based
    // on it. If both are unknown, return incomplete.
    const set = new Set([freq, divers])
    if (set.has(false)) return "red" // either (false, false) or (false, null)
    else if (set.has(true)) return "green" // either (true, true) or (true, null)
    else return "incomplete" // must be (null, null)
  }

  get dietQualitySummaryStatement(): string | null {
    if (this.ageInDays >= 183 && !this.textures.includes("solids")) {
      const divers = this.getMinimumDietDiversitySummary() as RecommendationItem
      return divers.interpretation
    }
    if (!isKnown(this.mf)) return // They didn't do this section.
    const freq = this.getMinimumMealFrequencyRecommendation() as RecommendationItem
    const divers = this.getMinimumDietDiversitySummary() as RecommendationItem
    if (freq && divers) {
      const results = [freq.interpretation, divers.interpretation].join(" ")
      return !results.trim() ? $gettext("Diet quality is unknown.") : results
    }
  }

  // if @asBoolean is true, returns true if child meets min meal frequency, false if not, and null if not known by caregiver
  getMinimumMealFrequencyRecommendation(asBoolean = false): RecommendationItem | boolean | null {
    // Breastfed first
    if (this.tools.includes("breast")) {
      // Breastfed and 6-9 months
      if (this.ageInDays >= 183 && this.ageInDays < 274) {
        if (this.mf === 9) {
          return asBoolean ? null : {
            id: "mf",
            recommendation: $gettext("Give the child food 2 to 3 times a day to help them grow strong and healthy. When the child is 9 months old, add one more meal and 1-2 small healthy snacks."),
          }
        }
        else if (this.mf >= 2) {
          return asBoolean ? true : {
            id: "mf",
            interpretation: $gettext("The child is getting the minimum recommended number of meals."),
            recommendation: $gettext("The child is eating the right number of meals, and this helps them grow well. Keep giving the child 2-3 meals every day. When they are 9 months old, add one more meal and 1-2 small healthy snacks."),
          }
        }
        else {
          return asBoolean ? false : {
            id: "mf",
            interpretation: $gettext("The child is getting less than the minimum recommended number of meals."),
            recommendation: $gettext("The child may not be eating enough meals each day, and this can make them grow poorly. Give them food 2 to 3 times a day to help them grow strong and healthy. When the child is 9 months old, add one more meal and 1-2 small healthy snacks."),
          }
        }
      }
      // Breastfed and 9-24 months
      else if (this.ageInDays >= 274 && this.ageInDays < 730) {
        if (this.mf === 9) {
          return asBoolean ? null : {
            id: "mf",
            interpretation: $gettext("Meal frequency is unknown."),
            recommendation: $gettext("Give the child 3-4 meals and 1-2 small healthy snacks every day to help them grow strong and healthy."),
          }
        }
        else if (this.mf >= 3) {
          return asBoolean ? true : {
            id: "mf",
            interpretation: $gettext("The child is getting the minimum recommended number of meals."),
            recommendation: $gettext("The child is receiving the right number of meals, and this helps them grow well. Keep giving them 3-4 meals and 1-2 small healthy snacks every day."),
          }
        }
        else {
          return asBoolean ? false : {
            id: "mf",
            interpretation: $gettext("The child is getting less than the minimum recommended number of meals."),
            recommendation: $gettext("The child may not be eating enough meals each day, and this can make them grow poorly. Give them 3-4 meals and 1-2 small healthy snacks every day to help them grow strong and healthy."),
          }
        }
      }
    }
    // Non-breastfed children 6-24 months
    else if (this.ageInDays >= 183 && this.ageInDays < 730) {
      if (this.mf === 9) {
        return asBoolean ? null : {
          id: "mf",
          recommendation: $gettext("Give the child at least 4 meals every day, including soft and solid foods and milk. You can also add 1 or 2 small, healthy snacks."),
        }
      }
      // If they don't know milk feed freq, just count meal freq.
      const score = this.mff === 9 || !isKnown(this.mff) ? this.mf : this.mf + this.mff
      if (score >= 4 && this.mf) {
        return asBoolean ? true : {
          id: "mf",
          interpretation: $gettext("The child is getting the minimum recommended number of meals."),
          recommendation: $gettext("The child is receiving the right number of meals, and this helps them grow well. Keep giving them at least 4 meals every day, including soft and solid foods and milk. They can also add 1 or 2 small, healthy snacks."),
        }
      }
      else if (score >= 4 && !this.mf) {
        return asBoolean ? false : {
          id: "mf",
          interpretation: $gettext("The child receives milk, but should also be eating soft or solid food."),
          recommendation: $gettext("The child should be receiving food in addition to milk to grow strong and healthy. Give them at least 4 meals every day, including soft and solid foods and milk. They can also add 1 or 2 small, healthy snacks."),
        }
      }
      else if (score < 4) {
        return asBoolean ? false : {
          id: "mf",
          interpretation: $gettext("The child is getting less than the minimum recommended number of meals."),
          recommendation: $gettext("The child may not be eating enough meals each day, and this can make them grow poorly. Give them at least 4 meals every day, including soft and solid foods and milk. They can also add 1 or 2 small, healthy snacks."),
        }
      }
    }
    // Children 24-72 months
    else if (this.ageInDays >= 730 && this.ageInDays < 2190) {
      if (this.mf === 9) {
        return asBoolean ? null : {
          id: "mf",
          recommendation: $gettext("Give the child 3-4 meals and 1-2 small healthy snacks every day to help them grow strong and healthy."),
        }
      }
      else if (this.mf >= 3) {
        return asBoolean ? true : {
          id: "mf",
          interpretation: $gettext("The child is getting the minimum recommended number of meals."),
          recommendation: $gettext("The child is getting the right number of meals, and this helps them grow well. Keep giving them 3-4 meals and 1-2 small healthy snacks every day."),
        }
      }
      else {
        return asBoolean ? false : {
          id: "mf",
          interpretation: $gettext("The child is getting less than the minimum recommended number of meals."),
          recommendation: $gettext("The child may not be eating enough meals each day, and this can make them grow poorly. Give them 3-4 meals and 1-2 small healthy snacks every day to help them grow strong and healthy."),
        }
      }
    }
  }

  getMinimumDietDiversitySummary(asBoolean = false): RecommendationItem | boolean {
    // First check to see if they completed this section.
    // Older than 6 months but no solids.
    if (this.ageInDays >= 183 && !this.textures.includes("solids")) {
      return asBoolean ? false : {
        id: "mdd",
        interpretation: $gettext("The child has not yet begun complementary foods."),
        recommendation: this.textures.includes("breast milk") ?
          $gettext("Start giving the child food if they are able to sit with or without support. Continue breastfeeding and give food 2 to 3 times a day to help them grow strong and healthy. Make sure food is in a texture that is easy for the child to eat.")
          :
          $gettext("Start giving the child food if they are able to sit with or without support. Give the child at least four meals a day, including food and milk. Give the child different foods every day. Make sure you prepare the food in a texture that is easy for the child to eat."),
        learnMore: $gettext("Give the child different foods every day. Try to include foods that help them grow strong like meat, chicken, eggs, fish, beans, peas, nuts and seeds. Give colorful fruits and vegetables like ripe mangoes and papayas, spinach, sweet potatoes, pumpkin, and carrots during the week. When introducing foods it is generally easy and safer to begin with pureed soft foods."),
      }
    }
    const dietRec = $gettext("Try to include foods that help them grow strong like meat, chicken, eggs, fish, beans, peas, nuts and seeds. Also, give colorful fruits and vegetables like ripe mangoes and papayas, spinach, sweet potatoes, pumpkin, and carrots during the week.")

    // Do we know enough to render a verdict? We need to know something about 4+ of the 6 food groups.
    const unknowns = [
      isAllUnknown(this.fg1a, this.fg1b),
      isAllUnknown(this.fg2a, this.fg2b, this.fg2c),
      isAllUnknown(this.fg3a, this.fg3b),
      isAllUnknown(this.fg4),
      isAllUnknown(this.fg5),
      isAllUnknown(this.fg6),
    ]
    if (unknowns.filter(i => i).length >= 4) return asBoolean ? null : {
      id: "mdd",
      interpretation: $gettext("Dietary diversity is unknown."),
      recommendation: $gettext("Give the child different foods every day to help them grow healthy and strong.") + " " + dietRec
    }

    const scorePerFoodGroup = [
      containsAYes(this.fg1a, this.fg1b) ? 1 : 0,
      containsAYes(this.fg2a, this.fg2b, this.fg2c) ? 1 : 0,
      containsAYes(this.fg3a, this.fg3b) ? 1 : 0,
      containsAYes(this.fg4) ? 1 : 0,
      containsAYes(this.fg5) ? 1 : 0,
      containsAYes(this.fg6) ? 1 : 0,
    ]
    const score = sum(scorePerFoodGroup)
    if (score >= 4) {
      return asBoolean ? true : {
        id: "mdd",
        interpretation: $gettext("The child’s diet includes a variety of foods."),
        recommendation: $gettext("The child is getting different kinds of food, and this helps them grow healthy and strong. Keep offering different foods every day.") + " " + dietRec,
      }
    }
    else {
      return asBoolean ? false : {
        id: "mdd",
        interpretation: $gettext("The child’s diet lacks diversity."),
        recommendation: $gettext("The child may not be eating enough different types of foods. If they eat the same foods all the time, they might not get enough nutrients, which can cause malnutrition. Give them different foods every day.") + " " + dietRec,
      }
    }
  }

  // Nutrient-specific indicators. These return:
  // - true if that nutrient needs are met
  // - false if that nutrient needs are known to not be met
  // - null if not enough is known to decide
  get vita(): boolean | null {
    const fields = [this.fg2a, this.fg2b, this.fg2c]
    if (isAllUnknown(...fields)) return
    return containsAYes(...fields)
  }
  get prot(): boolean | null {
    const fields = [this.fg4, this.fg5]
    if (isAllUnknown(...fields)) return
    return containsAYes(...fields)
  }
  get calc(): boolean | null {
    if (this.fg6 === "unknown" || this.ageInDays < 366) return
    return containsAYes(this.fg6)
  }
  get zfv(): boolean | null {
    const fields = [this.fg2a, this.fg2b, this.fg2c, this.fg3a, this.fg3b]
    if (isAllUnknown(...fields)) return
    return containsAYes(...fields)
  }
  get ufc(): boolean | null {
    if (this.fg7 === "unknown") return
    return containsAYes(this.fg7)
  }
  get swb(): boolean | null {
    if (this.fg8 === "unknown") return
    return containsAYes(this.fg8)
  }

  getDietaryConcerns(): Array<RecommendationItem> {
    const results = []
    if (this.vita === false) {
      results.push({
        id: "vita",
        interpretation: $gettext("Diet is low in vitamin A rich fruits and vegetables."),
        learnMore: $gettext("Fruits and vegetables with vitamin A help keep children healthy and protect their eyes. A child needs more of these foods. Try giving the child colorful fruits and vegetables like ripe mangoes and papayas, spinach, sweet potatoes, pumpkin, and carrots during the week."),
      })
    }
    if (this.prot === false) {
      results.push({
        id: "prot",
        interpretation: $gettext("Diet is lacking in protein and iron rich foods."),
        learnMore: $gettext("Foods that are high in protein and iron help children grow strong and keep their blood healthy. Try to include foods like meat, chicken, eggs, fish, beans, peas, nuts and seeds every day."),
      })
    }
    if (this.calc === false) {
      results.push({
        id: "calc",
        interpretation: $gettext("Diet is lacking in calcium rich foods."),
        learnMore: $gettext("Foods high in calcium help keep children’s bones and teeth healthy. Give the child foods like milk and yogurt every day."),
      })
    }
    if (this.zfv === false) {
      results.push({
        id: "zfv",
        interpretation: $gettext("Diet is lacking in fruits and vegetables."),
        learnMore: $gettext("Eating enough fruits and vegetables help children stay healthy. Include a variety of fruits and vegetables, especially ones that are colorful like ripe mangoes and papayas, spinach, sweet potatoes, pumpkin, and carrots during the week."),
      })
    }
    if (this.ufc) {
      results.push({
        id: "ufc",
        interpretation: $gettext("Diet includes processed foods."),
        learnMore: $gettext("Foods like candy, chocolate, and cakes do not give children the good things their bodies need to grow. If they eat too much of these foods, they might not want to eat healthier foods. Give the child these treats only once in a while. Instead, try to give them healthy snacks like fruits, yogurt, and nuts prepared in a way that is easy for them to eat."),
      })
    }
    if (this.swb) {
      results.push({
        id: "swb",
        interpretation: $gettext("Diet includes sweetened beverages."),
        learnMore: $gettext("Drinks like sodas, fruit-flavored drinks, and flavored milks do not help children grow and may hurt their teeth. These drinks can make them feel full, and they may not want to eat their meals. Instead, give the child milk, real fruit juice without added sugar, or vegetable juice."),
      })
    }
    return results
  }

  getReferrals() {
    // No referrals for Best Practices!
    return []
  }

  get isEditable() {
    return isAssessmentEditable(this)
  }

  get reportCard(): { stoplightColor: string, statements: Array<string> } {
    // Assumes this is a completed assessment!
    const statements = []
    if (this.breastfeedingStoplightColor === "red") {
      statements.push("Concerns about breastfeeding.") // XXX
    }
    if (this.responsiveFeedingStoplightColor === "red" || this.otherFeedingPracticesStoplightColor === "red") {
      statements.push("Feeding practices could be improved.")
    }
    if (this.dietQualityStoplightColor === "red") {
      statements.push("Diet quality is concerning.")
    }
    if (statements.length) {
      return { stoplightColor: "red", statements }
    }
    else {
      return { stoplightColor: "green", statements: [$gettext("Nutrition and feeding practices are appropriate for healthy growth and development.")] }
    }
  }
}
