<script  lang="ts" setup>
  import { ref } from "vue"
  import { useWindowSize } from '@vueuse/core'
  import IntroSlide from "@/components/IntroSlide.vue"
  const { width } = useWindowSize()
  const childComponentRef = ref(null)

  function finish() {
    childComponentRef.value.skipIntro()
  }
</script>
<template>
  <IntroSlide
    ref="childComponentRef"
    :current-number="2"
  >
    <h1 class="title is-spaced">
      How does it work?
    </h1>

    <h2 class="subtitle">
      Sites
    </h2>
    <p>
      <em>Count Me In</em> groups children in <em>sites</em>–places where children come <!--either periodically or daily--> for services (such as clinics or daycares), or where they actually live most of the time (such as orphanages).
      Select “My Sites” below to see the list of sites you have access to.
      <template v-if="width > 625">
        Select one of those sites to see or enter children or to begin assessing them.
      </template>
    </p>

    <h2 class="subtitle">
      Care Plans
    </h2>
    <p>
      Each child has an automatically generated care plan that summarizes the recommendations from their most recent assessments.
      They are useful to review with the child’s parent or caregiver.
      <!-- provide access to starting a new assessment.  -->
    </p>
    <template v-if="width > 625">
      <h2 class="subtitle">
        Schedules
      </h2>
      <p>
        <em>Count Me In</em> maintains an assessment schedule for each child in your site.
        The Site Home page shows who is due for each type of assessment, or you can consult the Calendar to see which days children are due.
      </p>

      <div class="text-center">
        <a
          class="button"
          @click="finish"
        >
          Finish
        </a>
      </div>
    </template>
  </IntroSlide>
</template>
<style scoped>
  h2.subtitle {
    margin-bottom: 0.75rem;
  }
</style>
